<template>
  <div class="listPage">
    <div class="tabHead">
      <Tabs v-model="status" @change='changeTab'>
        <Tab title="全部" name=''></Tab>
        <Tab title="进行中" name='3'></Tab>
        <Tab title="报名中" name='1'></Tab>
        <Tab title="未开始" name='0'></Tab>
        <Tab title="已结束" name='4'></Tab>
      </Tabs>
    </div>
    <div class="listBox" v-if="list.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="item in list">
            <div :key="item.id" class="meet">
              <div class="meetName" @click="goDetailPage(item.id)">
                <div class="name">
                  <img src="../../assets/meetList.png" width="20px" />
                  <span>{{item.meetTitle}}</span>
                </div>
                <Icon class="arrow" name="arrow" size="16" color="#666" />
              </div>
              <div class="meetName meetTime" @click="goDetailPage(item.id)">
                <div class="timeBox">
                  <div>
                    <span>开始时间：</span>
                    <span class="time">{{item.meetTimeStart}}</span>
                  </div>
                  <div>
                    <span>结束时间：</span>
                    <span class="time">{{item.meetTimeEnd}}</span>
                  </div>
                </div>
                <span :class="item.meetStatus == 1 ? 'blueColor' : ''">{{
                  ["未开始", "报名中","报名结束","进行中","培训结束" ][item.meetStatus]
                }}</span>
              </div>
            </div>
          </template>
        </List>
      </van-pull-refresh>
    </div>
    <div v-else class="noMeet">
      <Icon name="notes-o" color="#999" size='100'/>
      <span>暂时没有任何培训</span>
    </div>
    <HomeBtns/>
  </div>
</template>

<script>
import { Tab, Tabs, Icon, List } from "vant";
import HomeBtns from "../../components/homeBtns.vue"
import{getMeetList} from "@/api/meet.js"

export default {
  name: "meetList",
  components: {
    Tab,
    Tabs,
    Icon,
    List,
    HomeBtns
  },
  data() {
    return {
      status:'',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex:1,
      pageSize:10,
      total:0,
    };
  },
  created(){
    this.getMeetList(1);
  },
  methods: {
    //获取培训列表
    getMeetList(page){
      //0：未开始 1：报名中 2：报名结束 3：进行中 4：培训结束
      let data={
        meetStatus:this.status,
        pageIndex:page,
        pageSize:this.pageSize,
        meetTimeEnd:'',
        meetTimeStart:'',
        meetTitle:''
      }
      if((this.list.length<this.total)||page==1){
        getMeetList(data).then(res=>{
          this.loading = false;
          this.refreshing=false
          if(res.status=='S'){
            let {list,total}=res.data;
            let newList=page==1?list:[...this.list,...list];
            this.list=[...newList];
            this.pageIndex=page;
            this.total=total
          }
        })
      }else{
        this.finished = true;
        this.loading = false;
        this.refreshing=false
      }

    },
    changeTab(){
      this.total=0;
      this.finished = false;
      this.list=[];
      this.getMeetList(1)
    },
    goDetailPage(id){
        this.$router.push('/meetDetail?id='+id)
    },
    onLoad() {
      this.getMeetList(this.pageIndex+1);
    },
    onRefresh() {
      this.loading = true;
      this.total=0;
      this.finished = false;
      this.list=[];
      this.getMeetList(1)
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
::v-deep.van-tabs {
  .van-tab {
    color: #fff;
  }
  .van-tabs__nav--line {
    //padding-bottom: 0.2rem;
    background-image: url("../../assets/listHead.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .van-tabs__line {
    background-color: #fff;
    height: 2px;
    bottom: 0.25rem;
  }
  .van-tab--active {
    font-weight: bold;
  }
  .van-tabs__wrap {
    height: 0.435 * 1.25rem;
  }
}
.listBox {
    max-height: calc(~"100vh - 1.14375rem");
    overflow-y: auto;
    .meet{
      .meetName{
        .name{
          span{max-width:180px}
        }
      }
    }
  }
.noMeet{
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
      margin-top: 0.3rem;
      color:#999;
      font-size: 22px;
      font-weight: bold;
    }
  }
</style>
