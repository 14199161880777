<template>

    <div class="tabBox">
            <div :class="activeTab=='meetList'?'active':''" @click="goPage('meet')">
                <Icon name="cluster-o" size='21'/>
                <span>培训</span>
            </div>
            <div :class="activeTab=='my'?'active':''"  @click="goPage('my')">
                <Icon name="manager-o"  size='21'/>
                <span>我的</span>
            </div>
        </div>

</template>

<script>
import {Icon} from 'vant';
    export default {
        name:'homeBtns',
        components:{
            Icon
        },
        data(){
            return{
                activeTab:'',
            }
        },
        created(){
            this.activeTab=this.$route.name;
        },
        methods:{
            goPage(type){
                if(type=='meet'){
                    this.$router.push('/meetList')
                }else{
                    this.$router.push('/my')
                }
            },
        }
    }
</script>

<style lang="less" scoped>
.tabBox{
        width: 100vw;
        height: 0.6rem;
        background: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position:fixed;
        bottom:0;
        left: 0;
        background: #fff;
        border-top: 1px solid #eee;
        z-index: 100;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color:#999;
            font-size: 12px;
            span{
                margin-top: 0.03rem;
            }
        }
        .active{
            color: #2b72ff;
        }

    }
</style>
